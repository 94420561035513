<template>

    <div class="row">
      <template v-for="(field, i) in fields" :key="i">
        <div :class="`col-${getFieldWidth(field)}`">
    <template v-if="field.type === 'textarea'">
      <label for="basic-url">{{ field.name }}</label>
      <div class="input-group mb-3">
        <textarea
          class="form-control"
          v-model="localDTO[field.value]"
        ></textarea>
      </div>
    </template>
    <template v-else-if="field.type === 'datepicker'">
      <label for="basic-url">{{ field.name }}</label>
      <div class="input-group mb-3">
        <el-date-picker
          v-model="localDTO[field.value]"
          placeholder="Select a date"
          type="date"
          value-format="YYYY-MM-DDT00:00:00.000"
        />
      </div>
    </template>
    <template v-else-if="field.type === 'boolean'">
      <label for="basic-url">{{ field.name }}</label>
      <select class="form-control" v-model="localDTO[field.value]">
        <option :value="false">False</option>
        <option :value="true">True</option>
      </select>
    </template>
    <template v-else-if="field.type === 'upload'">
      <label for="basic-url">{{ field.name }}</label>
      <input
        class="form-control"
        type="file"
        id="formFile"
        @change="(event) => onUploadFile(event, field)"
      />
      <progress v-if="false" id="fileProgress" max="100" style="width: 100%; height: 20px;" :value="uploadProgresses[field.value].progress"></progress>
    </template>
    <template v-else-if="field.type === 'suggestion'">
      <v-select
        label="title"
        :uid="keyIndex + 12000 + '-' + field.value"
        :key="keyIndex + 12000 + '-' + field.value"
        v-model="suggestionValues[field.value]"
        @search="(search, loading) => fetchOptions(search, loading, field)"
        :options="suggestionOptions[field.value]"
      ></v-select>
    </template>
    <template v-else-if="field.type === 'textarea-i18n'">
      <label for="basic-url">{{ field.name }}</label>

      <div class="card-body border">
        <template
          v-for="(lang, iLang) in languages"
          :key="2000 + i * 100 + iLang"
        >
          <label for="basic-url">{{ $t(lang) }}</label>
          <div class="input-group mb-3">
            <textarea
              class="form-control"
              :readonly="field.readonly"
              v-model="i18nObjects[field.value][lang]"
            ></textarea>
          </div>
        </template>
      </div>
    </template>

    <template v-else-if="field.type === 'wysiwyg-i18n'">
      <label for="basic-url">{{ field.name }}</label>

      <div class="card-body border">
        <template
          v-for="(lang, iLang) in languages"
          :key="2000 + i * 100 + iLang"
        >
          <label for="basic-url">{{ $t(lang) }}</label>
          <div class="input-group mb-3">
            <ckeditor
              :editor="editor"
              v-model="i18nObjects[field.value][lang]"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </template>
      </div>
    </template>

    <template v-else-if="field.type === 'select'">
      <label for="basic-url">{{ field.name }}</label>
      <div class="input-group mb-3">
        <select class="form-control" v-model="localDTO[field.value]">
          <option
            v-for="(option, optionIndex) in field.options"
            :value="option.value"
            :key="8000 + i * 100 + optionIndex"
          >
            {{ option.name }}
          </option>
        </select>
      </div>
    </template>
    <template v-else-if="field.type === 'entity-list'">
      <label for="basic-url">{{ field.name }}</label>

      <div class="card-group">
        <div
          class="col-sm-3"
          v-for="(entity, entityIndex) in localDTO[field.value]"
          :key="4000 + entityIndex + i * 100"
        >
          <div class="card">
            <div class="card-body border">
              <img
                v-if="
                  field.imgProcessor &&
                  field.imgProcessor(localDTO[field.value][entityIndex]).valid
                "
                :src="
                  field.imgProcessor(localDTO[field.value][entityIndex]).url
                "
                class="w-100"
                alt="image"
              />

              <EditFields
                :key-index="keyIndex + entityIndex * i"
                :fields="field.fields"
                field-type="entity-list"
                :dto="entity"
                @updateDTO="localDTO[field.value][entityIndex] = $event"
              ></EditFields>
              <button
                class="btn btn-danger btn-sm"
                @click="deleteEntity(field, entityIndex)"
              >
                Remove
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <button
            class="btn btn-primary mt-2 btn-sm"
            @click="
              () => {
                if (!localDTO[field.value]) {
                  localDTO[field.value] = [];
                }
                localDTO[field.value].push({});
              }
            "
          >
            Add {{ field.name }}
          </button>
        </div>
      </div>
    </template>

    <template v-else-if="field.type === 'input-i18n'">
      <label for="basic-url">{{ field.name }}</label>

      <div class="card-body border">
        <template v-for="lang in languages" :key="lang">
          <label for="basic-url">{{ $t(lang) }}</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              v-model="i18nObjects[field.value][lang]"
              aria-describedby="basic-addon3"
            />
          </div>
        </template>
      </div>
    </template>

    <template v-else>
      <label for="basic-url">{{ field.name }}</label>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          v-model="localDTO[field.value]"
          aria-describedby="basic-addon3"
        />
      </div>
    </template>
        </div>
  </template>
    </div>

</template>
<script>
import { defineComponent } from "vue";
import vSelect from "vue-select";
import ApiService from "@/core/services/ApiService";
import { toast } from "vue3-toastify";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default defineComponent({
  name: "EditFields",
  emits: ["updateDTO"],
  components: {
    vSelect,
    ckeditor: CKEditor.component,
  },
  props: {
    fields: { type: Array },
    dto: { type: Object },
    keyIndex: { type: Number },
    fieldType: { type: String },
  },
  data: function () {
    return {
      localDTO: { ...this.dto },
      i18nObjects: {},
      uploadProgresses: {},
      suggestionOptions: {},
      suggestionValues: {},
      languages: ["ru", "en", "fr"],
      editorConfig: {
        // Конфигурация редактора
      },
      editor: ClassicEditor,
    };
  },

  watch: {
    suggestionValues: {
      handler(obj) {
        for (let value in obj) {
          this.localDTO[value] = obj[value] ? obj[value].id : null;
        }
      },
      deep: true,
    },

    i18nObjects: {
      handler(obj) {
        for (let value in obj) {
          this.localDTO[value] = this.$convertToLocalizedString(obj[value]);
        }
      },
      deep: true,
    },

    dto: {
      // Следим за изменениями пропа и обновляем локальную копию
      handler(newValue) {
        this.localDTO = newValue;
      },
      deep: true,
    },
    // Следим за изменениями локальной копии и уведомляем родителя
    localDTO: {
      handler(newValue) {
        this.$emit("updateDTO", newValue);
      },
      deep: true,
    },
  },

  created: function () {
    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];
      if (
        field.type === "textarea-i18n" ||
        field.type === "input-i18n" ||
        field.type === "wysiwyg-i18n"
      ) {
        let str = this.localDTO[field.value];
        if (str && str.startsWith("i18n")) str = str.substring(4, str.length);
        if (!str) str = "{}";

        this.i18nObjects[field.value] = JSON.parse(str);
      }

      if (field.type === 'upload') {
        this.uploadProgresses[field.value] = {
          progress: 0,
        }
      }

      if (field.type === "suggestion") {
        if (field.loadAll) {
          ApiService.get("api", field.url).then((response) => {
            this.suggestionOptions[field.value] = response.data;
          });
        }

        if (this.localDTO[field.value]) {
          ApiService.get(
            "api",
            field.url + "?id=" + this.localDTO[field.value]
          ).then((response) => {
            this.suggestionValues[field.value] = response.data[0];
          });
        }
      }
    }
  },

  methods: {
    /**
     * Triggered when the search text changes.
     *
     * @param search  {String}    Current search text
     * @param loading {Function}	Toggle loading class
     */
    fetchOptions(search, loading, field) {
      if (field.loadAll) return;

      loading(true);

      ApiService.get("api", field.url + `?query=` + search)
        .then((response) => {
          this.suggestionOptions[field.value] = response.data;
        })
        .finally(() => {
          loading(false);
        });
    },


    getFieldWidth(field) {
      if (field.type === 'textarea' || field.type === 'textarea-i18n' ||
          field.type === 'entity-list' || this.fieldType === 'entity-list' || field.type === 'input-i18n' ||
          field.type === 'wysiwyg-i18n') return 12; // Занимает всю строку
      return 4;                                 // По умолчанию для остальных типов
    },

    onUploadFile(event, field) {
      const fileInput = event.target;
      const file = fileInput.files[0];

      // Создаем FormData
      const formData = new FormData();
      formData.append("file", file, file.name);

      let params = "?";
      let hasPreview =
        field.previewValue &&
        (field.previewCondition == null ||
          field.previewCondition(this.localDTO));
      if (hasPreview) {
        params += "preview=true&";
      }

      const xhr = new XMLHttpRequest();
      xhr.open('POST', "/api/admin/upload" + params, true);
      xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("id_token"));
      xhr.upload.onprogress = () => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          this.uploadProgresses[field.value].progress = percentComplete;
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          let response = JSON.parse(xhr.responseText);
          this.localDTO[field.value] = this.getBaseUrl() + "/api/file/" + response.uuid;
          if (hasPreview && response.previewUUID) {
            this.localDTO[field.previewValue] = this.getBaseUrl() + "/api/file/" + response.previewUUID;
          }
          toast.success("File successfully uploaded");
        } else {
          console.error('Ошибка загрузки файла');
        }
      };

      xhr.onerror = () => {
        console.error('Ошибка загрузки файла');
      };

      xhr.send(formData);
    },

    deleteEntity(field, index) {
      this.localDTO[field.value].splice(index, 1);
    },

    getBaseUrl() {
      const { protocol, hostname, port } = window.location;
      const portPart = port ? `:${port}` : ""; // Добавляем порт только если он не стандартный
      return `${protocol}//${hostname}${portPart}`;
    },
  },
});
</script>
